<template>
  <div>
    <!-- end page-header -->
    <panel title="Panel title here">
      <!--            <p>-->
      <!--                {{ $t('Panel content here') }}-->
      <!--            </p>-->

      <!--            <button v-on:click="checkForm">Test</button>-->

      <service-points-selector
        :model="deliveryRequest"
        @change="handleChange"
      />
    </panel>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { HALApiService } from '../../../../services'
// import Centrifuge from 'centrifuge'
import { mapMutations } from 'vuex'

// var centrifuge = new Centrifuge('wss://centrifugo.orderadmin.eu/connection/websocket', {
//   debug: true,
//   subscribeEndpoint: 'https://alpha.orderadmin.eu/a.php',
//   subscribeHeaders: {
//     'Access-Control-Allow-Origin': '*'
//   },
//   subscribeParams: {}
// });

// centrifuge.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtQGlxY29tcGFueS5ydSIsIm5hbWUiOiJNYXhpbSJ9.5XO8gKLbHMTuIaE4_rDUj_f4uBYrudLjWihu5uO022w')

export default {
  components: {
    ServicePointsSelector: defineAsyncComponent(() => import('../../../../components/delivery-services/ServicePointsSelector.vue'))
  },
  data () {
    return {
      deliveryRequest: {},
      urlParams: null,
      deliveryServices: []
    }
  },
  created () {
    // this.$centrifuge.subscribe('$gossips', function (message) {
    //   console.log(message)
    // })
  },
  mounted () {
    this.loadDeliveryServices()

    this.urlParams = this.$route.query

    return HALApiService.get('/apps/shopify/delivery-requests/get/' + this.urlParams.order_id + '?token=' + this.urlParams.token).then(data => {
      this.deliveryRequest = data
    }).catch(error => {
      console.debug(error)
    })
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'startLoading',
      'stopLoading'
    ]),
    loadDeliveryServices (filter = {}) {
      this.startLoading()

      if (this.deliveryServices.length === 0) {
        return HALApiService.get('/delivery-services', filter).then(
          data => {
            if (this.deliveryServices === null) {
              this.deliveryServices = []
            }

            for (let i in data._embedded.delivery_services) {
              let row = data._embedded.delivery_services[i]

              this.deliveryServices.push(row)
            }

            this.stopLoading()
          })
          .catch(
            error => {
              this.addErrorNotification(error)

              this.stopLoading()
            }
          )
      } else {
        this.stopLoading()

        return new Promise((resolve, reject) => {
          return resolve(this.deliveryServices)
        })
      }
    },
    checkForm: function (e) {
      console.log(this.$centrifuge)

      this.$centrifuge.publish('$gossips', {'input': 'hi'}).then(function (res) {
        console.log('successfully published')
      }, function (err) {
        console.log('publish error', err)
      })
      //
      // centrifuge.publish("public:news", {"input": "hello"}).then(function(res) {
      //   console.log('successfully published');
      // }, function(err) {
      //   console.log('publish error', err);
      // });
    },
    handleChange (id) {
      if (typeof this.deliveryRequest.extId !== 'string') {
        // Temporary error, just to know what happen
        throw new Error('Shipping request have invalid data!')
      }

      let query = {
        'servicePoint': id
      }

      HALApiService.patch('/apps/shopify/delivery-requests/patch-delivery-request/' + this.deliveryRequest.extId + '?token=' + this.urlParams.token, query, undefined, '')
        .then(data => {
          this.stopLoading()
        })
        .catch(
          error => {
            this.addErrorNotification(error)

            this.stopLoading()
          }
        )
    }
  }
}
</script>
